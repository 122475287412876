<template>
    <div>
        <div>

            <ui-p v-if="loadingSub" center color="black">
                <span class="loader"></span><br>
                loading...
            </ui-p>

            <div
                v-if="!loadingSub"
            >
                <div
                    v-if="subscription == null || subscription === false"
                >

                    <div class="sub">
                        <div class="sub__wrapper">
                            <div class="sub__header">
                                <ui-h small>Pawsome plan</ui-h>
                            </div>
                            <div class="sub__body">

                                <p class="sub__cost">£7.99</p>

                                <p class="sub__permonth">per month</p>

                                <hr class="sub__spacer">
                           
                                <p class="sub__details">You can cancel your subscription at any time and will have access to help and support when using the app.</p>

                                <ui-p center>
                                    <ui-button
                                        primary 
                                        inline
                                        :loading="isLoading"
                                        v-on:tapped="sendToCheckout">Purchase</ui-button>
                                </ui-p>

                                <ui-p color="black" center>
                                    Any payment made is subject to our <router-link to="/terms">Terms &amp; Conditions</router-link>
                                </ui-p>



                                <ui-p color="black" v-if="isLoading">
                                    Please wait this will take a few moments...
                                </ui-p>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else>

                    <div 
                        class="warning"
                        v-if="subscription.status == 'past_due' || subscription.status == 'canceled'"
                    >
                        <ui-p
                            color="#ff0000"
                        >
                            There was an issue processing payment for your subscription renewal. We will attempt to take the payment automatically within the next two weeks and if in that event payment fails your subscription will be automatically canceled. The most likely cause is a lack of funds in your account or a change to your payment method.
                        </ui-p>

                        <ui-p
                            color="#ff0000"
                            v-if="subscription.status == 'past_due' || subscription.status == 'canceled'"
                        >
                            If you need any assistance please get in touch via <a href="mailto: info@diggdawg.com">info@diggdawg.com</a> or reach out on social media.
                        </ui-p>
                    </div>

                    <div class="sub">
                        <div class="sub__wrapper">
                            <div class="sub__header">
                                <ui-h small>Pawsome plan</ui-h>
                            </div>
                            <div class="sub__body">

                                <p class="sub__cost">£7.99</p>

                                <p class="sub__permonth">per month</p>

                                <hr class="sub__spacer">
                           
                                <ul class="sub__details">
                                    <li>Current status: {{subscription.status}}</li>
                                    <li>Purchased: {{moment.unix(subscription.created.seconds).format('MMMM Do YYYY')}}</li>
                                    <li>Next payment: {{moment.unix(subscription.current_period_end.seconds).format('MMMM Do YYYY')}}</li>
                                </ul> 

                                <ul 
                                    v-if="subscription.cancel_at != null"
                                    class="sub__details"
                                >
                                    <li>
                                        Cancelled: {{moment.unix(subscription.canceled_at.seconds).format('dddd MMMM Do YYYY')}}
                                    </li>

                                    <li>
                                        Last day: {{moment.unix(subscription.cancel_at.seconds).format('dddd MMMM Do YYYY')}}
                                    </li>
                                </ul>

                                <ui-p center>
                                    <ui-button 
                                        primary 
                                        inline
                                        :loading="isLoading" 
                                        v-on:tapped="sendToCustomerPortal">Amend
                                    </ui-button>
                                </ui-p>

                                <ui-p color="black" v-if="isLoading">
                                    Please wait this will take a few moments...
                                </ui-p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import firebase from "firebase/app";
import "firebase/firestore";
export default {
    data() {
        return {
            showSubscriptionStatus: false,
            isLoading: false,
        };
    },

    computed: {
        user() {
            return this.$store.getters.getterUser;
        },

        loadingSub() {
            return this.$store.getters.getterIsLoadingUserSub;
        },

        subscription() {
            return this.$store.getters.subscription
        },

        invoices() {
            return this.$store.getters.getterInvoices;
        },

        loadingInvoices() {
            return this.$store.getters.getterIsLoadingInvoices;
        },
    },

    methods: {
        async getCustomClaimRole() {
            await firebase.auth().currentUser.getIdToken(true);
            const decodedToken = await firebase.auth().currentUser.getIdTokenResult();

            return decodedToken.claims.stripeRole;
        },

        async sendToCustomerPortal() {
            this.isLoading = true;
            const functionRef = firebase
                .app()
                 //live
                .functions("europe-west1")

                // local
                //.functions("us-central1")
                .httpsCallable(
                    "ext-firestore-stripe-subscriptions-createPortalLink"
                );
            const { data } = await functionRef({
                returnUrl: window.location.origin,
            });
            window.location.assign(data.url);
        },

        async sendToCheckout() {
            this.isLoading = true;

            return firebase.default
                .firestore()
                .collection("users")
                .doc(this.user.uid)
                .collection("checkout_sessions")
                .add({
                    price: process.env.VUE_APP_STRIPE_MONTH_SUB_PRICE_KEY,
                    allow_promotion_codes: true,
                    success_url:
                        process.env.VUE_APP_BASE_URL +
                        process.env.VUE_APP_STRIPE_SUCCESS_URL,
                    cancel_url:
                        process.env.VUE_APP_BASE_URL +
                        process.env.VUE_APP_STRIPE_CANCEL_URL,
                })
                .then((docRef) => {
                    // Wait for the checkoutSession to get attached by the extension
                    docRef.onSnapshot(async (snap) => {
                        const { error, sessionId } = snap.data();
                        if (error) {
                            // Show an error to your customer and inspect
                            // your Cloud Function logs in the Firebase console.
                            this.$toasted.show(error.message, {
                                position: 'bottom-center',
                                theme: 'bubble'
                            })
                        }

                        if (sessionId) {
                            const stripe = await loadStripe(process.env.VUE_APP_STRIPE_KEY);
                            await stripe.redirectToCheckout({ sessionId });
                        }
                    });
                });
        },
    },
};
</script>

<style lang="scss">

.warning {
    padding: rem(20);
    color: $error;
    border: 2px solid $error;
}

.sub {
    background: #fff;
    border-radius: 2px;
    display: inline-block;
    margin: 1rem;
    position: relative;
    height: 100%;
    width: 300px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    @media (max-width: $mobile-bp) {
        width: calc(100% - 20px);
    }

    &__header {
        background: $primary;
        color: $white;
        padding: rem(20);
        text-align: center;

        h2 {
            margin: 0;
        }
    }

    &__body {
        height: 100%;
        padding: rem(50) rem(30) rem(10) rem(30);
    }

    &__cost {
        color: $primary;
        font-size: rem(36);
        margin: 0;
        text-align: center;
    }

    &__permonth {
        margin: 0 0 rem(20) 0;
        color: #aaaaaa;
        font-size: rem(14);
        text-align: center;
    }

    &__details {
        margin: rem(25) 0;
        font-size: rem(14);
        color: #aaaaaa;
        text-align: left;
    }

    &__spacer {
        width: 50%;
        height: 3px;
        background: $primary;
        margin: 0px auto;
    }
}
</style>